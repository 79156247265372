<template lang="html">
  <div v-if="finish">
    <!--<b>Ciao!</b><br><br> Questo spazio è riservato ad un utilizzo dashboard.<br>
    Richiedi subito grafici e statistiche personalizzate! <br><br>
    Scrivi ora a info@hyperialab.com o visita il <a href="https://hyperialab.com">sito web.</a>-->
    <div class="fixed-header-sub">
      Vedi dettaglio: <button @click="goback = (stats.cgr - (stats.cgr + el - 5))" :class="(goback == (stats.cgr - (stats.cgr + el - 5))) ? 'active-goback' : 'goback'" v-for="el in 5" :key="el+'goback'"> Rivista n° {{stats.cgr + el - 5}}</button>
    </div>
    <div class="fakebox"></div>
    <p class="alert"><b>NOTA:</b> I grafici fanno riferimento all'intero periodo dal numero {{stats.cgr - 5}} al {{stats.cgr}}</p>
    <br>
    <statistics-card-line
      icon="ShoppingBagIcon"
      :statistic="getNumber('active-all').total"
      :statisticTitle="'Totale abbonamenti attivi con il numero '+ (this.stats.cgr - this.goback) + ' ('+getNumber('active-all').digital+' digitali e '+getNumber('active-all').print+' cartacei)'"
      :chartData="getSeries('active-all')"
      :options="ordersReceviedChartData.chartOptions"
      color="#000"
      type="area" />
    <br>
    <vs-switch v-model="typeprint">
      <span slot="on">Abbonamenti cartacei</span>
      <span slot="off">Abbonamenti digitali</span>
    </vs-switch>
    <br>
    <statistics-card-line
      icon="ShoppingBagIcon"
      :statistic="getNumber('active')"
      :statisticTitle="'Abbonamenti attivi ' + (typeprint ? 'cartacei' : 'digitali') + ' con il numero '+ (this.stats.cgr - this.goback)"
      :chartData="getSeries('active')"
      :options="ordersReceviedChartData.chartOptions"
      color="#fff"
      type="area" />
      <div class="clearfix"></div>
      <statistics-card-line
      icon="ShoppingBagIcon"
      :statistic="getNumber('new')"
      :statisticTitle="'Nuovi abbonamenti che inziano con il numero '+ (this.stats.cgr - this.goback)"
      :chartData="getSeries('new')"
      :options="ordersReceviedChartData.chartOptions"
      color="success"
      type="area" />
      <div class="clearfix"></div>
      <statistics-card-line
      icon="ShoppingBagIcon"
      :statistic="getNumber('expired')"
      :statisticTitle="'Abbonamenti ' + (typeprint ? 'cartacei' : 'digitali') + ' che scadono con il numero '+ (this.stats.cgr - this.goback)"
      :chartData="getSeries('expired')"
      :options="ordersReceviedChartData.chartOptions"
      color="warning"
      type="area" />
      <div class="clearfix"></div>
      <statistics-card-line
      icon="ShoppingBagIcon"
      :statistic="getNumber('today')"
      :statisticTitle="'Abbonati '+ (typeprint ? 'cartacei' : 'digitali') + ' oggi'"
      :chartData="getSeries('today')"
      :options="ordersReceviedChartData.chartOptions"
      :hideChart="(getNumber('today') <= 1)"
      color="primary"
      type="area" />
      <div class="today-log">
        <span>LOG orario vendite giornaliero abbonamenti {{(typeprint ? 'cartacei' : 'digitali')}}</span>
        <p v-for="(el, i) in getToday()" :key="i+'log-daily'">Dalle ore {{el.hour}} alle ore {{el.hour + 1}} nuovi abbonamenti: <b> {{el.total}} </b></p>
      </div>
  </div>
</template>

<script>
import StatisticsCardLine from '@/components/statistics-cards/StatisticsCardLine.vue'

export default {
  components: {
    StatisticsCardLine
  },
  data () {
    return {
      finish: false,
      stats: null,
      goback: 0,
      typeprint: true,
      ordersReceviedChartData: {
        series: [
          {
            name: '',
            data: []
          }
        ],
        chartOptions: {
          grid: {
            show: false,
            padding: {
              left: 0,
              right: 0
            }
          },
          chart: {
            toolbar: {
              show: false
            },
            sparkline: {
              enabled: true
            }
          },
          dataLabels: {
            enabled: false
          },
          stroke: {
            curve: 'smooth',
            width: 2.5
          },
          fill: {
            type: 'gradient',
            gradient: {
              shadeIntensity: 0.9,
              opacityFrom: 0.7,
              opacityTo: 0.5,
              stops: [0, 80, 100]
            }
          }
        }
      }
    }
  },
  mounted () {
    this.getStats()
  },
  methods: {
    getNumber (query) {
      if (query === 'active-all') {
        const a = this.stats.print.active[0][`${this.stats.cgr - this.goback}`]
        const b = this.stats.digital.active[0][`${this.stats.cgr - this.goback}`]
        return {total: a + b, digital: b, print: a}
      }
      if (query === 'active') {
        if (this.typeprint) {
          return this.stats.print.active[0][`${this.stats.cgr - this.goback}`]
        } else {
          return this.stats.digital.active[0][`${this.stats.cgr - this.goback}`]
        }
      }
      if (query === 'new') {
        if (this.typeprint) {
          return this.stats.print.new[0][`${this.stats.cgr - this.goback}`]
        } else {
          return this.stats.digital.new[0][`${this.stats.cgr - this.goback}`]
        }
      }
      if (query === 'expired') {
        if (this.typeprint) {
          return this.stats.print.expired[0][`${this.stats.cgr - this.goback}`]
        } else {
          return this.stats.digital.expired[0][`${this.stats.cgr - this.goback}`]
        }
      }
      if (query === 'today') {
        let type = 'digital'
        if (this.typeprint) {
          type = 'print'
        }
        let total = 0
        for (let i = 0; i < this.stats[type].today.length; i++) {
          total += this.stats[type].today[i].total
        }
        return total
      }
      return 0
    },
    getSeries (query) {
      const serie = [
        {
          name: '',
          data: []
        }
      ]
      if (query === 'active-all') {
        serie[0].name = 'Abbonamenti totali'
        for (let i = 0; i < 5; i++) {
          serie[0].data.push((this.stats.print.active[0][`${this.stats.cgr - i}`] + this.stats.digital.active[0][`${this.stats.cgr - i}`]))
        }
        serie[0].data = serie[0].data.reverse()
        return serie
      }
      if (query === 'active') {
        if (this.typeprint) {
          serie[0].name = 'Abbonamenti totali cartacei'
        } else {
          serie[0].name = 'Abbonamenti totali digitali'
        }
        for (let i = 0; i < 5; i++) {
          let type = 'digital'
          if (this.typeprint) {
            type = 'print'
          }
          serie[0].data.push(this.stats[type].active[0][`${this.stats.cgr - i}`])
        }
        serie[0].data = serie[0].data.reverse()
        return serie
      }
      if (query === 'new') {
        if (this.typeprint) {
          serie[0].name = 'Nuovi abbonamenti cartacei'
        } else {
          serie[0].name = 'Nuovi abbonamenti digitali'
        }
        for (let i = 0; i < 5; i++) {
          let type = 'digital'
          if (this.typeprint) {
            type = 'print'
          }
          serie[0].data.push(this.stats[type].new[0][`${this.stats.cgr - i}`])
        }
        serie[0].data = serie[0].data.reverse()
        return serie
      }
      if (query === 'expired') {
        if (this.typeprint) {
          serie[0].name = 'Abbonamenti scaduti cartacei'
        } else {
          serie[0].name = 'Abbonamenti scaduti digitali'
        }
        for (let i = 0; i < 5; i++) {
          let type = 'digital'
          if (this.typeprint) {
            type = 'print'
          }
          serie[0].data.push(this.stats[type].expired[0][`${this.stats.cgr - i}`])
        }
        serie[0].data = serie[0].data.reverse()
        return serie
      }
      if (query === 'today') {
        if (this.typeprint) {
          serie[0].name = 'Abbonamenti ora per ora'
        } else {
          serie[0].name = 'Abbonamenti ora per ora'
        }
        let type = 'digital'
        if (this.typeprint) {
          type = 'print'
        }
        for (let i = 0; i < this.stats[type].today.length; i++) {
          serie[0].data.push(this.stats[type].today[i].total)
        }
        serie[0].data = serie[0].data.reverse()
        return serie
      }
      return serie
    },
    getToday () {
      let type = 'digital'
      if (this.typeprint) {
        type = 'print'
      }
      return this.stats[type].today
    },
    getStats () {
      this.finish = false
      this.$http.get('/api/dashboard/stats/')
        .then(response => {
          this.stats = response.data.data
          //console.log(this.stats)
          this.finish = true
        })
        .catch(() => {
          this.$vs.notify({title:'Warning', text:'Si è verificato un errore di connessione con il server.', color:'warning'})
        })
    }
  }
}
</script>
<style scoped>
.today-log span {
    font-size: 17px;
    margin: 10px 0px;
}
.today-log {
    width: 100%;
    text-align: center;
    background: #eaeaea;
    border-radius: 14px;
    margin-top: 15px;
    padding: 20px;
    color: black;
}
  .clearfix{
    margin-bottom: 15px;
    position: relative;
    clear:both;
  }
  .goback{
    padding: 5px 10px;
    margin-right: 5px;
    margin-left: 10px;
    border-radius: 5px;
    border: none;
    background: linear-gradient(45deg, #277f80, #3aacc4);
    color:white;
    cursor: pointer;
  }
  .active-goback{
    padding: 5px 10px;
    margin-right: 5px;
    margin-left: 10px;
    border-radius: 5px;
    border: none;
    background: linear-gradient(45deg, #d2e7e8, #06766742);
    border:1px solid #277f80;
    color:#277f80;
    cursor: pointer;
  }
  .alert {
    width:fit-content;
    margin-top: 15px;
    padding: 5px 10px;
    border-radius: 5px;
    background: rgb(247, 135, 31);
    color: white;
  }
  .fixed-header-sub {
    background: white;
    padding: 10px 15px;
    border-radius: 5px;
    position: fixed;
    top: 90px;
    z-index: 99;
    display: block;
    box-shadow: 0px 4px 25px 0px rgb(0 0 0 / 10%);
}
.fakebox{
  height: 30px;
}
</style>